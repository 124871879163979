<template>
  <Toolbar v-if="user" class="p-mb-4 p-mt-4">
    <template #start>
      <AddNewButton :label="$t('Add')" @click="addNewItem" />
    </template>
    <template #end>
<!--      <div class="p-input-icon-left">-->
<!--        <i class="pi pi-search" />-->
<!--        <InputText @input="searchItems($event.target.value)" v-model="searchData" :placeholder="$t('Search') + '...'" />-->
<!--      </div>-->
      <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
    </template>
  </Toolbar>
  <Spinner v-if="isLoading"></Spinner>
  <DesktopDataTable v-else
                    class="p-mt-4"
                    tableName="Customer cars"
                    :dataTableIsLoading="dataTableIsLoading"
                    :itemsArrayIsEmpty="itemsArrayIsEmpty"
                    :sort="sort"
                    :showSearchInput="false"
                    :actionButtonsCount="actionButtonsCount"
                    @sort-items="sortItems"
                    :headers="[
              {name: 'make', title: 'Make', sortable: false},
              {name: 'model', title: 'Model', sortable: false},
              {name: 'reg_number', title: 'Plate number', sortable: false},
              {name: 'year', title: 'Year', sortable: true},
              {name: 'vin', title: 'VIN code', sortable: false},
              {name: 'created_at', title: 'Created at', sortable: true},
          ]">
    <template v-slot:body>
      <tr v-for="(item, index) of items"
          :key="index"
          :class="{'updated-item': isItemUpdated(item.id)}">
        <td>
          <span v-if="item.make">{{ item.make.name || '' }}</span>
        </td>
        <td>
          <span v-if="item.model">{{ item.model.name || '' }}</span>
        </td>
        <td>{{ item.plate_number }}</td>
        <td>{{ item.year }}</td>
        <td>{{ item.vin }}</td>
        <td>
          <span class="table-small-text">
            <span v-if="item.created_at">{{ formatDate(item.created_at) || '' }}, </span>
            <span v-if="item.creator" class="table-date-line">
              <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>
              <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
            </span>
          </span>
        </td>
        <td>
          <div class="table__actions-column-data">
            <router-link :to="{ path: `/cars/${item.id}` }" target="_blank"><ViewButton/></router-link>
            <EditButton :disabled="(disableEditButton && isItemUpdated(item.id))"
                        v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant  || $store.state.user.role === constants.userRoles.moderator"
                        @click="editItem(item)" />
          </div>
        </td>
      </tr>
    </template>
  </DesktopDataTable>

  <OwnPaginator v-show="!itemsArrayIsEmpty"
                :rows="pagination.perPage"
                :currentPage="pagination.currentPage - 1"
                :totalPages="pagination.pageCount"
                :totalRecords="pagination.totalCount"
                :rowsPerPageOptions="[5,10,25,50,100,200]"
                :pageLinkSize="11">
  </OwnPaginator>

  <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

  <CarModal :visible="itemModal"
            :item="item"
            :userId="$route.params.id ? +$route.params.id : null"
            @close="closeItemModal"
            @update-items="updateItems">
  </CarModal>

</template>

<script>
import httpClient from '@/services/http.services'
import CarModal from '@/pages/cars/components/CarModal'
import formatMixins from '@/mixins/formatMixins'
import httpMixins from "@/mixins/httpMixins";
import datatableMixins from "@/mixins/datatableMixins";

export default {
  mixins: [ formatMixins, httpMixins, datatableMixins ],
  components: { CarModal },
  emits: ['update-items', 'change-customer-status'],
  props: {
    user: Object,
  },
	data() {
		return {
      fieldsExpand: 'creator',
      apiLink: 'car/get-customer-active-cars',
      updatedItemId: null,
      // sort: {
      //   column: 'id',
      //   direction: '',
      // },
		}
	},
  watch: {
    '$route'() {
      const route = this.$route.path.split('/')
      if (this.$route.path === '/login' || route.indexOf('customers') === -1 || route.indexOf('cars') === -1) {
        return false
      }
      this.checkRouteQuery()
      this.getItems()
    },
  },
  mounted() {
    // if (this.$store.state.user?.role || !this.$route.params.id) return false
    if (!this.$route.params.id) return false
    this.loadData()
  },
  methods: {
    test(v) {
      console.log(v)
    },
    loadData() {
      this.checkRouteQuery()
      this.getItems()
    },
    async getItems() {
      this.dataTableIsLoading = true
      try {
        // let httpAddress = `${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&expand=${this.fieldsExpand}`
        // if (this.searchData && this.searchProcessed) httpAddress += `&search=${this.searchData}`
        //
        // const { status, data, headers } = await httpClient.post(httpAddress, { id: this.$route.params.id })
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          data: { id: this.$route.params.id }
        })

        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.disableEditButton = false
        this.dataTableIsLoading = false
      }
    },
	},
  computed: {
    allDataLoaded() {
      return !!(this.masters && this.responsibles && this.taxes && this.services && this.suppliers && this.areas)
    },
    itemToDeleteData() {
      return 'delete data'
    },
    actionButtonsCount() {
      if (this.$store.state.user.role === this.constants.userRoles.superAdmin || this.$store.state.user.role === this.constants.userRoles.admin || this.$store.state.user.role === this.constants.userRoles.accountant  || this.$store.state.user.role === this.constants.userRoles.moderator) {
        return 2
      } else {
        return 1
      }
    },
  }
}
</script>